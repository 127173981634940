import "core-js/modules/esnext.iterator.map.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "90vh",
      "width": _vm.isMobile ? '90vw' : '700px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop-title"
  }, [_vm._v(_vm._s(_vm.$lang("Task")))]), _c('div', {
    staticClass: "taskbox"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("标题")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.title,
      expression: "modifyData.title"
    }],
    domProps: {
      "value": _vm.modifyData.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "title", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("图标")]), _c('div', {
    staticClass: "input file-upload"
  }, [_vm.modifyData.icon ? _c('img', {
    attrs: {
      "src": _vm.modifyData.icon,
      "height": "40px",
      "width": "40px"
    }
  }) : _vm._e(), !_vm.modifyData.icon ? _c('svg-icon', {
    attrs: {
      "name": "upload-filled"
    }
  }) : _vm._e(), _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": _vm.onFileChange
    }
  })], 1)]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("类型")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.type,
      expression: "modifyData.type"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "telegram"
    }
  }, [_vm._v("telegram")]), _c('option', {
    attrs: {
      "value": "channel"
    }
  }, [_vm._v("channel")]), _c('option', {
    attrs: {
      "value": "twitter"
    }
  }, [_vm._v("twitter")]), _c('option', {
    attrs: {
      "value": "discord"
    }
  }, [_vm._v("discord")]), _c('option', {
    attrs: {
      "value": "adsgram"
    }
  }, [_vm._v("adsgram")]), _c('option', {
    attrs: {
      "value": "tmads"
    }
  }, [_vm._v("tmads")]), _c('option', {
    attrs: {
      "value": "matrix"
    }
  }, [_vm._v("matrix")]), _c('option', {
    attrs: {
      "value": "wallet"
    }
  }, [_vm._v("wallet")]), _c('option', {
    attrs: {
      "value": "link"
    }
  }, [_vm._v("link")]), _c('option', {
    attrs: {
      "value": "video"
    }
  }, [_vm._v("video")]), _c('option', {
    attrs: {
      "value": "page"
    }
  }, [_vm._v("page")])])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("奖励")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.reward,
      expression: "modifyData.reward"
    }],
    domProps: {
      "value": _vm.modifyData.reward
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "reward", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("奖励KEY")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.reward_stock,
      expression: "modifyData.reward_stock"
    }],
    domProps: {
      "value": _vm.modifyData.reward_stock
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "reward_stock", $event.target.value);
      }
    }
  })])]), _vm.modifyData.type == 'video' ? _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("答案")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.answer,
      expression: "modifyData.answer"
    }],
    domProps: {
      "value": _vm.modifyData.answer
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "answer", $event.target.value);
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("地址")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.url,
      expression: "modifyData.url"
    }],
    domProps: {
      "value": _vm.modifyData.url
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "url", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-tip"
  }, [_vm._v(" 如果是 adsgram 请填写 BlockId"), _c('br'), _vm._v(" 如果是 tmads 请填写app key ")]), ['telegram', 'channel', 'page'].includes(_vm.modifyData.type) ? _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("目标名")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.target,
      expression: "modifyData.target"
    }],
    domProps: {
      "value": _vm.modifyData.target
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "target", $event.target.value);
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("状态")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.status,
      expression: "modifyData.status"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("启用")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("禁用")])])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("强验证")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.verifiable,
      expression: "modifyData.verifiable"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "verifiable", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("是")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("否")])])])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("限制完成数")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.limit_count,
      expression: "modifyData.limit_count"
    }],
    domProps: {
      "value": _vm.modifyData.limit_count
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "limit_count", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-tip"
  }, [_vm._v(" 0为不限制 ")]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("排序")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.sort,
      expression: "modifyData.sort"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.modifyData.sort
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "sort", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-tip"
  }, [_vm._v(" 越小排越前 ")]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("备注")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.remarks,
      expression: "modifyData.remarks"
    }],
    domProps: {
      "value": _vm.modifyData.remarks
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "remarks", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    staticClass: "btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v(_vm._s(_vm.$lang("Submit")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };