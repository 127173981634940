var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "search-box btns"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_vm._v(" 用户ID： "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.condition.user_id,
      expression: "condition.user_id"
    }],
    domProps: {
      "value": _vm.condition.user_id
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.condition, "user_id", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-item"
  }, [_vm._v(" 获奖时间： "), _c('m-date-range-picker', {
    attrs: {
      "value": _vm.time
    },
    on: {
      "update:value": function ($event) {
        _vm.time = $event;
      },
      "inputFn": _vm.onDatePickerInput
    }
  })], 1), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("查询中...")] : [_vm._v("查询")]], 2)]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.user_id) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.item_title) + " ")]), _c('div', {
      staticClass: "s icon"
    }, [item.item_icon ? _c('img', {
      attrs: {
        "src": item.item_icon,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s reward"
    }, [_vm._v(" " + _vm._s(item.item_currency) + " ")]), _c('div', {
      staticClass: "s reward"
    }, [_vm._v(" " + _vm._s(item.item_amount) + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(item.win_time_format) + " ")]), _c('div', {
      staticClass: "s reward"
    }, [_vm._v(" " + _vm._s(item.bsc_address) + " ")])]);
  })], 2), _c('div', {
    staticClass: "data-bd"
  }, [_c('my-pagination', {
    staticClass: "pagination",
    attrs: {
      "total": _vm.total,
      "page-size": _vm.condition.limit,
      "page": _vm.condition.page,
      "on-page-change": _vm.onPageChange,
      "on-page-size-change": _vm.onPageSizeChange
    }
  })], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm._v(" ID ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 获奖用户ID ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 赢得奖项 ")]), _c('div', {
    staticClass: "s icon"
  }, [_vm._v(" 奖项图标 ")]), _c('div', {
    staticClass: "s reward"
  }, [_vm._v(" 奖励货币 ")]), _c('div', {
    staticClass: "s reward"
  }, [_vm._v(" 奖励金额 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 获奖时间 ")]), _c('div', {
    staticClass: "s reward"
  }, [_vm._v(" NFT空投地址 ")])]);
}];
export { render, staticRenderFns };